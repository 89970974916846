.card {
  cursor: pointer;
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25));
}

.dash {
  font-family: "Roboto";
  display: flex;
  height: 100%;
  margin-top: 2%;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  gap: 15px;
}

.request-notification {
  font-family: "Atkinson Hyperlegible", sans-serif;
  font-weight: bolder;
  position: absolute;
  top: 0.55rem;
  right: 0.55rem;
  font-size: x-large;
  background-color: #363636;
  color: white;
  width: 36px;
  height: 36px;
  border-radius: 30%;
}

.card-name-heading {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.font {
  font-family: "Atkinson Hyperlegible", sans-serif;
}

.font-heading {
  font-family: "Atkinson Hyperlegible";
  font-style: normal;
  font-weight: 700;
}

.call-card {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
