.local-video {
  border-radius: 25px;
  border: 1px solid white;
}

.local-video-nocamera {
  border-radius: 25px;
  border: 1px solid black;
  background-color: black;
  position: absolute;
  width: 300px;
  height: 300px;
  top: 10px;
  right: 10px;
}

.local-name {
  position: absolute;
  top: 100px;
  right: 100px;
  color: white;
  z-index: 100;
}

.local-name-cameraon {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  z-index: 100;
}

.remote-name-cameraon {
  position: absolute;
  top: 15px;
  left: 15px;
  color: black;
  z-index: 9999;
}

.remote-video-border {
  overflow: hidden;
  border-radius: 30px;
  width: 73vw;
  /* min-height: 100%; */
  position: absolute;
  height: 86vh;
  z-index: -1;
  top: 5px;
  left: 5px;
  right: 5px;
  margin-left: auto;
  margin-right: auto;
}

.remote-video {
  /* width: 100vw;
  height: 100vh; */
  /* width: 100%;
  height: 100%; */

  /* position: absolute;
  width: 100%;
  height: 100%;
  /* width: 100%;
  height: 100%; */
  /* margin-left: auto;
  margin-right: auto;
  left: 10px;
  right: 10px;
  top: 10px;
  bottom: 20px;
  z-index: -1; */

  /* overflow: hidden;
  border-radius: 10px;
  width: 120%;
  min-height: 100%;
  position: fixed;
  height: 120%;
  z-index: -1;
  top: 10px;
  bottom: 0px;
  margin-left: auto; */

  /* width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0; */
}
