.feedback-pg {
  font-family: "Atkinson Hyperlegible", sans-serif;
  display: flex;
  height: 100%;
  margin-top: 4%;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  gap: 0.5rem;
  margin-left: 25%;
  margin-right: 25%;
}

.feedback-btn-group {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.feedback-btn {
  border-color: #393939;
  margin: 0.5rem;
  padding: 1.5rem;
}

.skip-submit-btn {
  margin-top: 3rem;
}
