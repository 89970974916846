.control-btns {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  background-color: #363636;
  height: 13vh;
}

.control-toggle {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}

.topic-btn {
  margin-right: auto;
  padding-left: 10px;
  border-radius: 10px;
}

.inner-topic-btn {
  margin-right: auto;
  padding-left: 10px;
  margin-bottom: 1rem;
  border-radius: 10px;
}

.topic-btn-clickable:hover {
  background-color: #e2e8f0;
}

.track-btns {
  margin-left: 6rem;
}

.options-btns {
  margin-right: 20px;
}

.leave-btn {
  margin-left: auto;
  padding-right: 10px;
}

.close-btn {
  margin-right: 90px;
}

.tooltip {
  padding: 12px;
}
