.primary-user {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 1%;
  margin-left: 25%;
  margin-right: 25%;
}

.profile-pic {
  display: flex;
  position: relative;
  float: left;
  /* left: 1rem; */
  margin-bottom: 0.5rem;
  font-family: "Atkinson Hyperlegible", sans-serif;
}

.user-name {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 4px;
}

.heading {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.picture {
  position: relative;
}

.picture-upload-btn {
  margin-top: 5px;
}

.flex-item-bottom-right {
  order: 1;
  flex: 0 1 auto;
  align-self: flex-end;
}

.heading-child-online {
  margin-top: 10px;
  margin-left: 0.5rem;
}

.user-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.interests {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tags {
  display: flex;
  gap: 10px;
  margin-bottom: 1rem;
}

.edit-icon {
  cursor: pointer;
  margin-left: 10px;
}

.about-section {
  font-family: "Atkinson Hyperlegible", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.editable {
  margin-left: 10px;
}

.alert-notif {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 2rem;
  margin-left: 25%;
  margin-right: 25%;
}
