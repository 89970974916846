.card-display {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* align-items: center;
  text-align: center; */
}

.also-users-text {
  text-align: left;
}

.bolded-text {
  font-weight: bold;
}

.disclaimer-msg {
  font-family: "Atkinson Hyperlegible", sans-serif;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.meet-friends-pg {
  font-family: "Atkinson Hyperlegible", sans-serif;
  display: flex;
  margin-top: 1%;
  flex-direction: column;
  /* text-align: left; */
  min-height: 100vh;
  gap: 20px;
  margin-left: 20%;
  margin-right: 20%;
}

.friend-requests {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.friend-request {
  margin-left: 20px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  margin-bottom: 15px;
}

.friend-request-card {
  display: flex;
  flex-direction: row;
  width: 900px;
}

.profile-card {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.card-item {
  width: 30%;
  margin-bottom: 10px; /* (100-32*3)/2 */
  margin-left: 20px;
}

.user-invite-heading {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 20px;
}

.user-invite-buttongroup {
  margin-left: auto;
}

.user-invite-avatar {
}

.friend-request-card-content {
  display: flex;
  flex-direction: row;
}
