.field-pg {
  font-family: "Atkinson Hyperlegible", sans-serif;
  display: flex;
  height: 100%;
  margin-top: 1%;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  gap: 20px;
}

.blocked-people-title {
  margin-left: 25%;
  margin-right: 25%;
  text-align: left;
  margin-bottom: 2rem;
  font-family: "Atkinson Hyperlegible", sans-serif;
}
