.room {
  position: relative;
  font-family: "Atkinson Hyperlegible", sans-serif;
  height: calc(100vh - 13vh);
  background-color: #363636;
  z-index: 0;
}

.alert-style {
  border-radius: 10px;
}

.sidebar {
  z-index: 999;
  background-color: #363636;
  border-radius: 10px;
  font-family: "Atkinson Hyperlegible", sans-serif;
}

.controls {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 2;
}

.description {
  float: left;
}

.title-sidebar {
  display: flex;
  flex-flow: row wrap;
  direction: row;
  align-content: flex-start;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-bottom: 0.5rem;
  z-index: 1;
}

.leave-sidebar-title {
  display: flex;
  flex-flow: column wrap;
  direction: column;
  align-content: center;
  padding-top: 1.25rem;
}

.title-description {
  display: flex;
  flex-flow: column wrap;
  direction: column;
  align-content: flex-start;
  padding-left: 2rem;
  padding-bottom: 0.5rem;
}

.local-participant {
  position: absolute;
  width: 300px;
  height: 300px;
  top: 10px;
  right: 10px;
}

.btn-display {
  display: flex;
  flex-flow: column wrap;
  align-content: flex-start;
}

.remote-participants {
  /* position: absolute;
  width: 100vw;
  height: 100vh; */
  /* z-index: -999; */
}

.prompt-icon {
  margin-right: 50px;
}

.waiting-div {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 60vh;
}
