.first-last-name {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 2px;
}

.welcome-pg {
  font-family: "Atkinson Hyperlegible", sans-serif;
  display: flex;
  height: 100%;
  margin-top: 10%;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  gap: 15px;
}

.field-pg {
  font-family: "Atkinson Hyperlegible", sans-serif;
  display: flex;
  height: 100%;
  margin-top: 4%;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  gap: 20px;
}

.btn {
  font-family: "Atkinson Hyperlegible", sans-serif;
  padding: 10px;
  width: 280px;
}

.selection-btn {
  width: 400px;
}

.btn-lingoshare-black {
  font-family: "Atkinson Hyperlegible", sans-serif;
  background-color: #393939;
  color: white;
}

.page-title {
  margin-left: 25%;
  margin-right: 25%;
  text-align: left;
  margin-bottom: 2rem;
  font-family: "Atkinson Hyperlegible", sans-serif;
}
