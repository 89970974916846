.navbar {
  display: flex;
}

.avatar {
  margin-top: 5px;
  padding-right: 15px;
}

.logo {
  align-self: flex-start;
  padding: 15px;
  cursor: pointer;
}

.title {
  font-family: "Atkinson Hyperlegible", sans-serif;
  font-weight: bold;
  font-size: 40px;
  padding-left: 10px;
  margin-bottom: 3px;
}

.nav-title {
  font-family: "Atkinson Hyperlegible", sans-serif;
  font-weight: bold;
  font-size: 30px;
  margin-top: 7px;
}

.homepage-logo {
  display: flex;
}

.logout-btn {
  margin-left: auto;
  padding: 20px;
}

.user-icon {
  width: 30px;
  height: 30px;
}

.menu-user-icon {
  width: 22px;
  height: 22px;
}

.translate-btn {
  cursor: pointer;
  /* margin: 15px; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
}

.top-right-settings {
  margin-left: auto;
  display: flex;
  margin-top: 0.5rem;
}
